<template lang="html">
  <div>
    <template v-for="(link,key) in links">
      <v-link
          :key="key"
          :url="link.url"
          :label="link.label"
          :target="link.target"
          :fallbackToPlainText="link.fallbackToPlainText"
          :print="link.print"
          />
    </template>
  </div>
</template>

<script>
import VLink from "@/components/domElements/VLink";
export default {
    components: {VLink},
    props: {
        links: {
            type: Array,
            required: true
        },
    }
}
</script>

<style lang="css" scoped>
</style>
