<template>
    <div class="d3-pie-container d-flex justify-content-center">
        <div class="d3-pie-item pie-container">
            <div class="d3-pie" ref="pieChart" :style="{ width, height }"></div>
        </div>
        <div v-if="hasLegend" class="legend-container d3-pie-item">
            <div class="legend">
                <div v-for="(item, key) in chartData"
                    :key="key"
                    class="legend-item"
                    :class="{ hover: item.hover }"
                    @mouseover="changeHover(item, true)"
                    @mouseleave="changeHover(item, false)">

                    <svg height="10" width="10" :style="{ fill: item.color || setColor(key) }">
                        <circle cx="4" cy="4" r="4" />
                    </svg>

                    <v-host-link
                        v-if="item.id"
                        :label="item.label"
                        :id="item.id"
                        class="legend-item__caption"
                        :print="print"/>
                    <span
                        v-else
                        class="legend-item__caption">
                        {{ item.label }}
                        {{ item.id }}
                    </span>
                    <div class="legend-item__number">{{ item.value }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {cloneDeep, debounce, isNumber} from 'lodash';
import {select as d3select} from 'd3-selection';
import 'd3-transition';
import * as d3ease from 'd3-ease';
import {arc as d3arc, interpolate as d3interpolate, pie as d3pie} from 'd3';

export default {
    name: 'D3PieChart',
    props: {
        data: {
            type: Array,
            required: true,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        hasLegend: {
            type: Boolean,
            default: true,
        },
        legendWidth: {
            type: String,
            default: '100%',
        },
        width: {
            type: String,
            default: '230px',
        },
        height: {
            type: String,
            default: '230px',
        },
        margin: {
            type: Object,
            default: () => ({ top: 10, bottom: 10 }),
        },
        print: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            chartData: null,
            arcNormal: null,
            arcOver: null,
            colors: [
                '#2291ff',
                '#002d88',
                '#9dceff',
                '#7BBEFF',
                '#004466',
                '#003399',
                '#006699',
                '#003EBB',
                '#006Fdd',
                '#0044CC',
                '#ACACAC',
                '#95BFD5',
                '#8a8a8a',
            ]
        }
    },
    watch: {
        data: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    this.safeDraw();
                });
            },
        },
        width: {
            deep: false,
            handler() {
                this.$nextTick(() => {
                    this.safeDraw();
                });
            },
        },
        height: {
            deep: false,
            handler() {
                this.$nextTick(() => {
                    this.safeDraw();
                });
            },
        },
    },
    mounted() {
        setTimeout(this.safeDraw);
        this._handleResize = debounce(this.onResize, 500);
        window.addEventListener('resize', this._handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this._handleResize);
    },
    methods: {
        changeHover(item, value) {
            item.hover = value;
            const select = d3select(this.$refs.pieChart).select(`.${item.key}`);
            if (value) {
                this.animateArc(select, 'easeLinear', this.arcOver);
            } else {
                this.animateArc(select, 'easeBounce', this.arcNormal, 500);
            }
        },
        animateArc(select, ease, target, duration = 300) {
            select
                .transition()
                .duration(duration)
                .ease(d3ease[ease])
                .attr('d', target);
        },
        drawPie() {
            this.chartData = cloneDeep(this.data.map(el => ({ ...el, hover: false })));

            for ( let i in this.chartData ) {
                const item = this.chartData[i]
                if ( item.label.startsWith('{') ) {
                    let obj = JSON.parse(item.label)
                    this.chartData[i].label = obj.name
                    this.chartData[i].id = obj.id
                }
            }

            const {
                left = 0,
                top = 0,
                right = 0,
                bottom = 0,
            } = this.margin;
            const {
                innerRadius = 70,
                cornerRadius = 0,
                padAngle = 0.01,
                animationDuration = 1000,
                defaultColor = 'rgb(212, 212, 212)',
            } = this.options;
            const [w, h] = this.getElWidthHeight();
            const gw = w - left - right;
            const gh = h - top - bottom;
            const outerRadius = Math.min(gw / 2, gh / 2);

            if (![gw, gh].every(el => el > 0) || outerRadius <= innerRadius) {
                return;
            }

            const svg = d3select(this.$refs.pieChart)
                .append('svg')
                .attr('width', w)
                .attr('height', h);

            const g = svg
                .append('g')
                .attr('transform', `translate(${left},${top})`)
                .append('g')
                .attr('transform', `translate(${gw / 2},${gh / 2})`);

            const pie = d3pie()
                .sort(null)
                .value(d => (!d.isEmpty ? d.value : 1));

            this.arcNormal = d3arc()
                .innerRadius(innerRadius)
                .outerRadius(outerRadius)
                .cornerRadius(cornerRadius)
                .padAngle(padAngle);

            this.arcOver = d3arc()
                .innerRadius(innerRadius)
                .outerRadius(outerRadius + outerRadius * 0.06)
                .cornerRadius(cornerRadius)
                .padAngle(padAngle);

            const enter = g
                .selectAll('path')
                .data(pie(this.chartData))
                .enter();

            const paths = enter.append('path');

            paths
                .transition()
                .duration(isNumber(animationDuration) ? animationDuration : 0)
                .attrTween('d', d => {
                    const interpolate = d3interpolate({ endAngle: d.startAngle }, d);
                    return t => this.arcNormal(interpolate(t));
                })
                .on("end", () => {
                    paths.on('mouseover', (d, i, j) => {
                        d.data.hover = true;
                        this.animateArc(d3select(j[i]), 'easeLinear', this.arcOver);
                    })
                    .on('mouseout', (d, i, j) => {
                        d.data.hover = false;
                        this.animateArc(d3select(j[i]), 'easeBounce', this.arcNormal, 500);
                    })
                })
                .attr('fill', (d,k) => (d.data.color || this.setColor(k) || defaultColor))
                .attr('class', d => (`${d.data.key} ${d.data.css}` || d.data.key))
        },
        getElWidthHeight() {
            return [this.$refs.pieChart.clientWidth, this.$refs.pieChart.clientHeight];
        },
        resetSvg() {
            const svgSelection = d3select(this.$refs.pieChart).select('svg');
            if (!svgSelection.empty()) {
                svgSelection.remove();
            }
        },
        safeDraw() {
            this.resetSvg();
            this.drawPie();
        },
        onResize() {
            this.safeDraw();
        },
        setColor(index) {
          const length = this.colors.length;
          if ( index > length ) {
            const newIndex = index % length;
            return this.colors[newIndex];
          }
          return this.colors[index];
        },
    },
};
</script>

<style scoped>
    .d3-pie {
        display: flex;
        align-items: center;
        margin: auto;
    }
    .d3-pie-container  {
        display: flex;
        flex-wrap: wrap;
        vertical-align: middle;
        align-self: flex-end;
    }
    .d3-pie-item {
        flex: 1 1 100%;
    }
    .pie-container {
        max-width: 400px;
    }
    .legend-container {
        display: flex;
        align-items: center;
    }
    .legend {
        display: block;
        width: 400px;
    }
    .legend-item {
        font-size: 12px;
        padding: 1px 6px;
        border-radius: 4px;
        margin-bottom: 6px;
    }
    .legend-item.hover {
        background-color: #DFDFDF;
    }
    .legend-item svg {
        display: inline-block;
        vertical-align: top;
        margin-top: 3px;
        width: 5%;
        max-width: 10px;
    }
    .legend-item__caption {
        display: inline-block;
        vertical-align: top;
        padding-left: 6px;
        line-height: 1.2;
        width: 80%;
        font-size: 12px;
    }
    .legend-item__number {
        display: inline-block;
        vertical-align: top;
        text-align: right;
        width: 15%;
    }
    .d3-pie text {
        user-select: none;
    }
</style>
