<template lang="html">
    <hds-gray-menu
        class="wr-menu"
        :items="menuItems"
        :controlled-from-outside="true"
        :collapsed="true"/>
</template>

<script>
import { mapGetters } from 'vuex'
import ReportIcon from '@/assets/report.svg';
import CalendarIcon from '@/assets/calendar.svg';

export default {
    computed: {
        ...mapGetters([
            'isUserAdmin'
        ]),
        menuItems() {
            let menuItems = [
                {
                    label: this.$t('Reports'),
                    name: 'reports',
                    path: '/reports',
                    iconUrl: ReportIcon,
                }
            ];

            if ( this.isUserAdmin ) {
                menuItems.push({
                    label: this.$t('Schedules'),
                    name: 'schedules',
                    path: '/schedules',
                    iconUrl: CalendarIcon
                })
            }
            return menuItems
        }
    }
}
</script>

<style lang="css" scoped>
    .wr-menu >>> .hds-gray-menu {
        padding-top: 15px;
    }
</style>
