<template>
    <div id="app" class="wr-app page-not-rendered" :class="{'hds-layout-container': layout != 'layout-print' }">
        <component :is="layout">
            <hds-spinner
                v-if="isLoading"
                :includeLine="false" />
            <router-view v-else></router-view>
        </component>

        <hds-toaster />
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    const defaultLayout = "main"

export default {
    computed: {
        ...mapGetters([
            'isLoading',
            'loggedIn'
         ]),
        layout() {
            if ( !this.loggedIn ) {
                return 'layout-background-only'
            }
            return  'layout-' + ( this.$route.meta.layout || defaultLayout )
        }
    }
}
</script>

<style>
    .wr-td-action {
        width: 66px;
    }
    .wr-app .hds-header {
        background: #fff;
        padding: 0 24px 0;
    }
    .wr-app .hds-layout-content {
        background: #fff;
    }
    .wr-app .hds-h2 {
        font-size: 22px;
    }
    .hds-layout-container {
      height: 100% !important;
      width: 100%;
    }
</style>
