<template lang="html">
    <div class="wr-report-page" :class="reportClass">
        <div class="hds-layout-header hds-header">
            <div class="hds-header-wrapper-title">
                <div>
                    <report-selector :id="id" :reports="reportsList" :print="print" />
                    <div v-if="!print" class="wr-action-wrap">
                        <button
                            @click="printReport(id)"
                            class="hds-button-link">
                            <hds-svg-icon icon="print" color="interactive" />
                            {{ $t('Print') }}
                        </button>
                    </div>
                </div>

                <div class="hds-header-subtitle">
                    <div
                        v-if="print"
                        class="hds-margin-x-small-top">
                        {{ $t('Domain') }}: <domain-selector :print="true" />
                    </div>
                    <period-selector
                        class="hds-margin-small-top"
                        :print="print"
                        v-if="reportDesc && reportDesc.timeDependent"
                        :value="period"
                    />
                </div>
            </div>
        </div>

        <div class="hds-layout-content">
            <hds-spinner
                v-if="loading"
                :includeLine="false"
                :spinnerSize="35" />

            <template v-else>
                <empty-state
                    v-if="Object.keys(preparedData).length === 0"
                    :title="emptyStateMessage.title"
                    :description="emptyStateMessage.desc"
                    class="hds-container-alignment-centralized"/>

                <div
                    v-for="(dataset, dkey) in preparedData"
                    :key="dkey"
                    class="hds-margin-xx-large-bottom">

                    <template  v-if="preparedData.length > 1" >
                        <h2 class="hds-h2">
                            {{ $t('Data set') }} {{ dkey + 1 }}
                        </h2>
                        <h3 class="hds-h3">
                           {{ $t("Found") }} {{ dataset.numFound }} {{ $t('report_table.custom.types.' + dataset.documentType) }}
                        </h3>
                    </template>

                    <div
                        v-for="(props, key) in dataset"
                        :key="key">

                        <pie-chart
                            v-if="key === 'pieChart'"
                            :data="props"
                            :options="options"
                            :print="print"
                        />

                        <report-table
                            v-if="key === 'table'"
                            :name="props.name"
                            :columns="props.columns"
                            :rows="props.rows"
                            :config="{
                                totalCount: dataset.numFound ? dataset.numFound : props.totalCount,
                                hasLimitOptions: props.hasLimitOptions}"
                            :hasColumnsSelector="true"
                            :print="print"
                            :reportId="id"
                        />

                        <tree-map
                            v-if="key === 'treeMapChart'"
                            :data="props"
                        />

                        <div v-if="key === 'facets'">
                            <report-table
                                v-for="(facet, key) in props"
                                :key="key"
                                :name="facet.name"
                                :columns="facet.columns"
                                :rows="facet.rows"
                                :config="{totalCount: facet.totalCount, hasLimitOptions: false}"
                                :isFacet="true"
                                :print="print"
                                :reportId="id"
                            />
                        </div>

                        <report-summary
                            v-if="key === 'summary'"
                            :data="props"
                            :print="print"
                        />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import {isArray, keys} from 'lodash'
    import ReportTable from "../components/viewParts/report/ReportTable";
    import {TableConfiguration} from '../mixins/tableConfiguration.js'

    function toTreeMapItem(chartItem) {
        let item = {
            key: chartItem.key,
            name: this.$t("alert." + chartItem.key),
            value: chartItem.count
        }
        if (isArray(chartItem.children)) {
            item.children = chartItem.children.map(childItem =>
                toTreeMapItem.call(this, childItem)
            )
        }
        return item
    }

    export default {
        components: {ReportTable},
        props: {
            report: [Number, String],
            domainId: null,
            scheduleType: String,
            tableConfig: Object,
            print: {
                type: Boolean,
                default: false
            },
            period: String
        },
        methods: {
            ...mapActions([
                'printReport',
                'loadReportData'
            ]),
            loadData(id) {
                this.loading = true
                if (this.report) {
                    this.id = this.report
                } else {
                    this.id = id
                }
                this.loadReportData({
                    reportId: this.id,
                    domainId: this.domainId,
                    tableConfig: this.tableConfig || undefined,
                    period: this.period || null
                }).then(() => {
                    this.loading = false
                    this.loaded = true
                }).catch(() => {
                    this.loading = false
                    this.loaded = true
                })
            }
        },
        computed: {
            ...mapGetters([
                'reportsList',
                'reportDescription',
                'reportsData'
            ]),
            options() {
                if (this.print) {
                    return {
                        animationDuration: 0
                    }
                }
                return {}
            },
            reportClass() {
                let className = 'wr-report-'

                if ( this.reportDesc && this.reportDesc.predefined ) {
                    className += this.id.replace(/\s+/g, '-').toLowerCase();
                } else {
                    className += 'custom'
                }

                return className
            },
            preparedData() {
                const data = []
                const reportData = this.reportsData.find(data => data.id === this.id)
                const hasLimitOptions = this.reportDesc && this.reportDesc.hasLimitOptions ? this.reportDesc.hasLimitOptions : false
                if ( typeof reportData === 'undefined' ) {
                    return []
                }

                let reportId = reportData.id
                let localizationPrefix = 'report_table.' + reportId + '.';

                for ( let i in reportData.datasets ) {
                    let dataset = reportData.datasets[i]
                    data[i] = {}

                    for (let key in dataset) {
                        const part = dataset[key]

                        if (key === 'pieChart' && keys(part.data).length) {
                           let pieChart = keys(part.data).map((label, index) => {
                                return {
                                    key: "pie-segment-" + index,
                                    label: label,
                                    value: part.data[label]
                                }
                            })
                            if (part.sortByCount) {
                                pieChart.sort((entry1, entry2) => entry1.value > entry2.value ? -1 : 1)
                            } else {
                                pieChart.sort((entry1, entry2) => entry1.label.localeCompare(entry2.label))
                            }

                            data[i] = {
                                ...data[i],
                                pieChart: pieChart
                            }
                        }

                        if (key === 'table' && part.rows.length) {
                            data[i] = {
                                ...data[i],
                                table: TableConfiguration.methods.create(
                                    part.rows,
                                    part.columnTypes,
                                    part.totalCount,
                                    hasLimitOptions,
                                    (key) => this.$t(localizationPrefix + key),
                                    {
                                        name: part.name,
                                        predefined: reportData.predefined,
                                        columnNames: part.columnNames,
                                        print: this.print
                                    }
                                )
                            }
                        }

                        if (key === 'treeMapChart' && keys(part.data).length) {
                            data[i] = {
                                ...data[i],
                                treeMapChart: {
                                    key: 'root',
                                    name: '',
                                    children: part.data.map(item => toTreeMapItem.call(this, item))
                                }
                            }
                        }

                        if (key === 'facets' && part.length) {
                            let facets = []
                            for ( let f in part ) {
                                let facet = part[f]

                                facets.push(TableConfiguration.methods.create(
                                    facet.rows,
                                    facet.columnTypes,
                                    facet.totalCount,
                                    hasLimitOptions,
                                    () => {},
                                    {
                                        name: facet.name,
                                        predefined: reportData.predefined,
                                        columnNames: facet.columnNames,
                                        print: this.print
                                    }
                                ))
                            }

                            data[i] = {
                                ...data[i],
                                facets: facets
                            }
                        }

                        if (key === 'summary' && keys(part).length) {
                            let summary = {}
                            let tables = ['top_discovered_updates','top_infections']

                            for (let t in tables) {
                                let table = part[tables[t]]
                                summary[tables[t]] = TableConfiguration.methods.create(
                                    table.rows,
                                    table.columnTypes,
                                    table.totalCount,
                                    hasLimitOptions,
                                    (key) => this.$t(localizationPrefix + key),
                                    {
                                        name: table.name,
                                        predefined: true,
                                        print: this.print
                                    }
                                )
                            }

                            data[i] = {
                                ...data[i],
                                summary: {
                                    ...part,
                                    ...summary
                                }
                            }
                        }
                    }

                    if ( Object.keys(data[i]).length > 0 ) {
                        data[i] = {
                            ...data[i],
                            documentType: dataset.documentType,
                            numFound: dataset.numFound
                        }
                    }
                }

                return data.filter(value => Object.keys(value).length !== 0)
            },
            emptyStateMessage() {
                let msg = {
                    title: '',
                    desc: ''
                }
                if ( typeof this.reportDesc === 'undefined' ) {
                    msg.title = this.$t('Report not found')
                } else if (Object.keys(this.preparedData).length === 0) {
                    msg.title = this.$t('No data')
                    msg.desc = this.$t('There is no data for this report')
                }
                return msg
            }
        },
        data() {
            return {
                id: null,
                loading: false,
                reportDesc: null,
                loaded: false,
                emitted: false
            }
        },
        created() {
            let queryPeriod = this.$router.currentRoute.query.period;
            if (queryPeriod) {
                this.period = queryPeriod
            }

            const id = this.report ? this.report : this.$router.currentRoute.params.id
            this.reportDesc = this.reportDescription(id)

            if ( typeof this.reportDesc !== 'undefined' ) {
                this.loadData(id)
            } else {
                this.loaded = true
            }
        },

        beforeRouteUpdate(to, from, next) {
            this.loadData(to.params.id)
            this.reportDesc = this.reportDescription(to.params.id)
            next()
        },
        updated() {
            if ( this.loaded && !this.emitted ) {
                // Emit event to parent, then data loaded and prepared
                this.$emit('loaded')
                this.emitted = true
            }
        }
    }
</script>

<style lang="css" scoped>
    .wr-report-page {
        height: 100%;
    }
    .hds-layout-content {
        min-height: 100%;
        padding: 24px;
        overflow: auto;
    }
    .hds-header-wrapper-title {
        margin-bottom: 15px;
    }
    .hds-header-subtitle {
        margin: 0;
    }
    .hds-table td {
        white-space: pre-wrap;
        word-break: break-all;
    }
    .wr-action-wrap {
        display: inline-block;
        vertical-align: top;
        margin: -4px 0 0 25px;
    }
    .hds-button-link {
        text-decoration: none;
    }
    .hds-button-link svg {
      width: 16px;
    }
    .wr-report-custom .hds-layout-content {
        background: #f8f8f8;
    }
    .wr-report-custom .wr-table-report {
        padding: 20px;
        border: 1px #e5e5e5 solid;
        border-radius: 3px;
        background: #fff;
    }
    .wr-print .wr-report-page .hds-layout-content {
        position: relative;
        min-height: 190px;
    }
    .wr-print-layout .wr-table-report {
        border: none;
        background: none;
        padding: 0;
    }
    .hds-h2 {
        margin: 0 0 10px;
    }
    .hds-h3 {
      margin: 0 0 24px;
    }
</style>
