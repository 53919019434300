<template lang="html">
    <button
        @click="method(params)"
        class="hds-button-link">
        {{ text }}
    </button>
</template>

<script>
export default {
    props: {
        method: {
            type: Function,
            required: true
        },
        params: {
            type: [Number, String, Array, Object],
            required: true
        },
        text: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="css" scoped>
    .hds-button-link {
        text-align: left;
        text-decoration: none;
        padding: 0;
        min-height: auto;
    }
    .hds-button-link:hover {
        background: none;
    }
    .hds-button-link:focus {
        box-shadow: none;
    }
</style>
