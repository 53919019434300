<template lang="html">
    <router-link :to="to">
        {{ text }}
    </router-link>
</template>

<script>
    export default {
        props: {
            to: {
                type: [String, Object],
                required: true
            },
            text: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="css" scoped>
</style>
