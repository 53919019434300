<template lang="html">
    <div class="wr-print-layout">
        <div class="hds-container-narrow">
            <div class="hds-text-center hds-padding-base">
                <div class="hds-product-title">WithSecure™ Policy Manager <span>Web Reporting</span></div>
            </div>
        </div>

        <router-view />
    </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
    .wr-print-layout >>> .hds-container-narrow-large {
        width: auto;
    }
    .wr-print-layout >>> .hds-background-main {
        box-shadow: none;
    }
    .hds-layout-container {
        height: auto;
    }
    .hds-container-narrow {
        max-width: 750px;
    }
</style>
