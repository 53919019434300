<template lang="html">
    <label class="hds-input-label">
        {{ $t(text) }}
        <span v-if="required">*</span>
    </label>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="css" scoped>
    span {
        color: #bf0001
    }
</style>
