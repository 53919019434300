<template lang="html">
    <div class="hds-position-relative wr-period-selector">
        {{ $t('period_selector.time_period') }}:
        <span v-if="print">
            {{ periodName }}
        </span>

        <hds-scope-selector
            v-else
            :companies="options"
            v-model="period"
            @change="onChangePeriod(period.id)"  />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'

    export default {
    props: {
        print: {
            type: Boolean,
            default: false
        },
        value: {
            type: String
        }
    },
    computed: {
        ...mapGetters([
            'reportPeriod'
        ]),
        periodName() {
            return this.periods.find( el => el.id == this.period.id ).name
        },
        options() {
            return this.periods.slice(0, -1)    // exclude 'all' option, left for troubleshooting
        }
    },
    methods: {
        ...mapActions([
            'changeReportPeriod'
        ]),
        onChangePeriod(periodId) {
            localStorage['time-period'] = periodId
            this.changeReportPeriod(periodId)
        }
    },
    data() {
        return {
            period: 'week',
            periods: [
                {
                    id: 'day',
                    name: this.$t('period_selector.last_day')
                }, {
                    id: 'week',
                    name: this.$t('period_selector.last_week')
                }, {
                    id: 'month',
                    name: this.$t('period_selector.last_month')
                }, {
                    id: 'quarter',
                    name: this.$t('period_selector.last_quarter')
                }, {
                    id: 'all',
                    name: this.$t('period_selector.all')
                },
            ]
        }
    },
    created() {
        let period = this.value ? this.value : localStorage['time-period']

        this.period = this.periods.find( el => {
            if ( period ) {
                return el.id === period
            }
            return el.id === this.reportPeriod
        })

        if ( !this.print && this.period !== this.reportPeriod) {
            this.changeReportPeriod( this.period.id )
        }
    }
}
</script>

<style lang="css" scoped>
    .wr-period-selector >>> .hds-scope-selector {
        position: relative;
        top: auto;
        left: auto;
        margin: 0;
    }
    .wr-period-selector >>> .hds-scope-selector .hds-searchbox {
        margin: 0;
    }
    .wr-period-selector >>> .hds-scope-selector .hds-scope-selector-container-item-search {
        display: none;
    }
    .wr-period-selector >>> .hds-scope-selector-container {
        margin-top: 30px;
        padding-top: 0;
    }
    .wr-period-selector.hds-header-new >>> .hds-scope-selector__active .hds-scope-selector-button-company {
        color: #fff;
    }
    .wr-period-selector >>> .hds-scope-selector-button {
        display: block;
        height: auto;
        padding: 0;
        color: #006fdd;
        border: none;
    }
    .wr-period-selector >>> .hds-scope-selector-button:hover {
        color: #05a;
        background: none;
    }
    .wr-period-selector >>> .hds-scope-selector-button:focus {
        box-shadow: none;
    }
    .wr-period-selector >>> .hds-scope-selector-button .hds-icons {
        fill: #a6a6a6;
        margin-left: 5px;
    }
    .wr-period-selector >>> .hds-scope-selector-button-company {
        margin-left: 2px;
    }
</style>
