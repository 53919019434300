<template lang="html">
    <div class="hds-layout-container">
        <app-menu />

        <div class="hds-layout-main hds-position-relative">
            <app-header />

            <div class="hds-layout-content hds-content">
                <router-view></router-view>
            </div>

            <hds-action-panel :heading="$t('Selected reports') + ` (${selectedReports.length})`" :open="Boolean(selectedReports.length)" @close="selectReports([])">
              <div class="hds-action-panel-content-actions">
                <button class="hds-button-secondary" @click="printReport(selectedReports)">{{ $t("Print") }}</button>
              </div>
            </hds-action-panel>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'

    import Header from '../components/layout/Header.vue'
    import Menu from '../components/layout/Menu.vue'

    export default {
    components: {
        'app-header': Header,
        'app-menu': Menu
    },
    methods: {
        ...mapActions([
            'printReport',
            'selectReports'
        ])
    },
    computed: {
        ...mapGetters(['selectedReports'])
    },
    watch: {
        $route: {
            handler() {
                this.selectReports([]);
            },
            immediate: true
        }
    }
}
</script>

<style lang="css">
    .hds-layout-main {
        padding-top: 55px;
    }
    .hds-header-wrapper-title {
        margin: 20px 0 30px;
    }
</style>
