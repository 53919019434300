<template lang="html">
    <div class="hds-container hds-container-narrow hds-container-alignment-centralized">
        <div class="hds-row">
            <div class="hds-col hds-background-main"
                 v-bind:class="{ 'has-error': wrongCredentials}">
                <h2 class="hds-container-title">
                    {{ $t('login.log_in') }}
                </h2>
                <div v-show="wrongCredentials" class="alert alert-danger" v-html="$t('login.incorrect_credentials')"></div>
                <v-input
                    ref="username"
                    v-model.trim="form.username"
                    @keyup.enter="submit()"
                    :placeholder="'login.user_name'"/>
                <v-input
                    type="password"
                    v-model="form.password"
                    @keyup.enter="submit()"
                    :placeholder="'login.password'"/>

                <hds-button
                    @click="submit()"
                    :disabled="$v.$invalid"
                    width="full">
                    {{ $t('login.log_in') }}
                </hds-button>

            </div>
            <div class="hds-col hds-background-secondary">
                <h2 class="hds-container-title">{{ $t('login.welcome') }}</h2>
                <p>{{ $t('login.welcome_message') }}</p>
                <p v-html="$t('login.message_to_previous_version', { link: '/legacyWebReporting' })"></p>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import {required} from 'vuelidate/lib/validators'

    export default {
    validations: {
        form: {
            username: {
                required
            },
            password: {
                required
            },
        }
    },
    methods: {
        ...mapActions([
            'login'
        ]),
        submit: function() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.login(this.form)
            }
        }
    },
    computed: {
        ...mapGetters(['wrongCredentials'])
    },
    data() {
        return {
            form: {
                username: '',
                password: ''
            }
        }
    },
    created() {
        this.$nextTick(() => {
            this.$refs.username.$el.querySelector("input").focus();
        })
    }
}
</script>

<style lang="css" scoped>
    .alert {
        line-height: 20px;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 4px;
        border: 1px solid;
        border-left-width: 4px;
        font-size: .875rem;
        font-weight: 400;
    }
    .alert-danger {
        border-color: #bf0001;
        color: #bf0001;
        background-color: rgba(191,0,1,.1);
    }
</style>
