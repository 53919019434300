<template lang="html">
    <div>
        <div class="hds-layout-header hds-header">
            <div class="hds-header-wrapper-title">
                <h1 class="hds-header-title">{{ $t(this.$route.meta.title) }}</h1>
            </div>
        </div>
        <div class="hds-padding-xx-large hds-margin-x-large-bottom">
            <hds-table
                :columns="columns"
                :rows="rows"
                :selected="selectedReports"
                @selectedRows="selectedRows"
                :loading="loading"
                :config="config" />
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    methods: {
        ...mapActions([
            'selectReports',
            'loadReportsList'
        ]),

        selectedRows({ rows }) {
            this.selectReports(rows)
        }
    },
    computed: {
        ...mapGetters([
            'reportsList',
            'selectedReports'
        ]),
        rows() {
            const rows = [];
            for ( let key in this.reportsList ) {
                const report = this.reportsList[key];
                rows.push({
                    id: report.id,
                    name: {
                        to: { name: 'report', params: { id: report.id } },
                        text: report.predefined ? this.$t('report.' + report.id) : (report.name || report.id)
                    },
                    tags: { tags: report.categories },
                    actions: { id: report.id }
                });
            }
            return rows
        }
    },
    data() {
        return {
            loading: false,
            config: {
                checkboxValueId: 'id',
                emptyMessage: this.$t('no_data_available')
            },
            columns: [
                {
                    id: 'checkbox',
                    checkbox: true,
                    title: '',
                }, {
                    id: 'name',
                    component: 'v-router-link',
                    title: this.$t('Report name'),
                    sorting: false
                }, {
                    id: 'tags',
                    title: this.$t('Category tag'),
                    component: 'report-cell-tags',
                    sorting: false
                }, {
                    id: 'actions',
                    component: 'report-cell-actions',
                    cssClass: 'wr-td-action',
                    sorting: false
                },
            ]
        }
    },
    created() {
        this.loadReportsList()
    }
}
</script>

<style lang="css" scoped>
    >>> .hds-table .wr-strong {
        font-weight: bold;
    }
</style>
