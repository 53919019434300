<template lang="html">
    <span v-if="print">
        {{ selectedDomain.name }}
    </span>
    <hds-scope-selector
        v-else
        class="wr-domain-selector"
        :companies="domains"
        :selectedCompany="selectedDomain"
        @change="selectDomain"  />
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    props: {
        print: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters([
            'domains',
            'selectedDomain'
        ])
    },
    methods: {
        ...mapActions([
            'selectDomain'
        ])
    }
}
</script>

<style lang="css" scoped>
    .wr-domain-selector.hds-scope-selector {
        position: relative;
        top: auto;
        left: auto;
    }
    .wr-domain-selector >>> .hds-searchbox {
        margin: 0;
    }
    .wr-domain-selector >>> .hds-scope-selector-container {
        margin-top: 52px;
        padding-top: 0;
    }
    .wr-domain-selector >>> button.hds-scope-selector-button .hds-scope-selector-button-company {
        color: #fff;
    }
    .wr-domain-selector >>> .hds-scope-selector-button {
        box-shadow: none;
        border: none;
    }
</style>
