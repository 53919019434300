<template lang="html">
    <div class="hds-layout-container hds-background">
        <div class="hds-container-alignment-top">
            <div class="hds-product-title hds-product-title-big">WithSecure™ Policy Manager Web Reporting</div>
        </div>

        <router-view />

        <div class="hds-container-alignment-bottom hds-text-center">
          <hds-svg-icon icon="logo-horizontal" color="bright" size="x-large" class="bottom-logo"/>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
  .hds-container-alignment-top {
    margin: 0 auto;
  }

  .hds-product-title {
    color: white;
  }

  .bottom-logo {
    height: 120px!important;
    width: 120px!important;
  }
</style>
