<template lang="html">
    <div class="hds-time-picker">
        <date-picker
            :value="value"
            :minute-step="15"
            format="HH:mm"
            value-type="format"
            type="time"
            placeholder="HH:mm"
            class="hds-time-picker"
            input-class="hds-input hds-input-time"
            @input="setTime">

            <template v-slot:header="{ emit }">
                <div class="hds-row hds-text-center">
                    <div class="hds-col">
                        {{ $t('time_picker.hour') }}
                    </div>
                    <div class="hds-col">
                        {{ $t('time_picker.minute') }}
                    </div>
                </div>
            </template>
            <template v-slot:icon-clear>
                <button class="hds-clear-button" type="reset">
                    <hds-svg-icon icon="close" size="small" color="info" />
                </button>
            </template>
            <template v-slot:icon-calendar>
                <span></span>
            </template>
        </date-picker>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    components: { DatePicker },
    props: {
        value: {
            required: true,
            validator: prop => typeof prop === 'string' || prop === null
        }
    },
    methods: {
        setTime(value) {
            this.$emit('input', value)
        }
    }
}
</script>

<style lang="css" scoped>
    .hds-time-picker {
        width: 100%;
        margin: 0;
    }
    .hds-time-picker >>> .mx-input-wrapper .mx-icon-clear {
        display: block;
        font-size: 12px;
        top: 18px;
        right: 0;
        height: 20px;
        width: 33px;
    }
    .hds-time-picker >>> .hds-clear-button {
        position: relative;
        top: auto;
        bottom: auto;
        right: auto;
    }
</style>
<style lang="css">
    .mx-datepicker-main {
        color: #212121;
    }
</style>
