<template lang="html">
    <div>
        <div class="hds-container hds-container-alignment-centralized hds-text-center">
            <div class="hds-error-page-code">404</div>
            <div class="hds-error-page-title">{{ $t("Page not found") }}</div>
            <div class="hds-error-page-description"></div>
            <div class="hds-error-page-actions">
                <router-link to="/" class="hds-button">{{ $t("Go to homepage") }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
    .hds-button {
        color: #fff;
        line-height: 24px;
    }
</style>
