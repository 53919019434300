<template lang="html">
    <div>
        <div class="hds-layout-header hds-header">
            <div class="hds-header-wrapper-title">
                <div class="hds-row">
                    <div class="hds-col">
                        <h1 class="hds-header-title">{{ $t(this.$route.meta.title) }}</h1>
                    </div>
                    <div class="hds-col hds-text-right">
                        <button
                            @click="openWizard(null)"
                            class="hds-button">
                            {{ $t('schedule_wizard.title.create') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="hds-padding-xx-large hds-margin-x-large-bottom">
            <div
                v-if="scheduleStatus && scheduleStatus != 'ok'"
                class="hds-alert hds-alert-close hds-alert-attention">
                <div class="hds-alert__icon">
                  <hds-svg-icon icon="warning" />
                </div>
                <p v-html="$t('schedule.status.' + scheduleStatus)"  class="hds-alert__message"></p>
            </div>

            <hds-table
                :columns="columns"
                :rows="rows"
                :config="{ emptyMessage: this.$t('no_data_available') }"
                :loading="loading" />
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import {wizardMixin} from '@/mixins/wizard'
    import {linkSorting} from '@/mixins/tableConfiguration'

    export default {
    mixins: [wizardMixin],
    methods: {
        ...mapActions([
            'loadSchedules',
            'loadScheduleStatus'
        ])
    },
    computed: {
        ...mapGetters([
            'schedules',
            'flatDomains',
            'reportDescription',
            'scheduleStatus'
        ]),
        rows() {
            const rows = [];
            if ( this.loading ) {
                return rows
            }
            for ( let key in this.schedules ) {
                const schedule = this.schedules[key];
                const domain = this.flatDomains.find(el => {
                    return el.id === schedule.domainId
                })

                let reports = []
                for (let index in schedule.reports) {
                    let reportId = schedule.reports[index]
                    const report = this.reportDescription(reportId) || { predefined: false, name: this.$t('removed_custom_report') }
                    reports.push(report.predefined ? this.$t('report.' + reportId) : report.name)
                }

                rows.push({
                    id: schedule.id,
                    reports: {
                        method: this.openWizard,
                        params: schedule.id,
                        label: reports.join(', '),
                        text: reports.join(', ')
                    },
                    domain: domain.name,
                    frequency: this.$t('schedule_wizard.frequency.type.' + schedule.schedule.scheduleType),
                    recipients: schedule.recipients,
                    actions: {
                        type: 'schedule',
                        id: schedule.id
                    }
                });
            }
            return rows
        }
    },
    data() {
        return {
            loading: false,
            columns: [
                {
                    id: 'reports',
                    component: 'v-link-button',
                    title: this.$t('Reports'),
                    sorting: linkSorting
                }, {
                    id: 'domain',
                    title: this.$t('Domain')
                }, {
                    id: 'recipients',
                    title: this.$t('schedule_wizard.recipients')
                }, {
                    id: 'frequency',
                    title: this.$t('schedule_wizard.frequency.label')
                }, {
                    id: 'actions',
                    component: 'report-cell-actions',
                    cssClass: 'wr-td-action',
                    sorting: false
                },
            ]
        }
    },
    created() {
        this.loading = true
        this.loadScheduleStatus()
        this.loadSchedules().then(() => {
            this.loading = false
        })
    }
}
</script>

<style lang="css" scoped>
</style>
