<template lang="html">
    <div>
        <v-label
            v-if="label"
            :text="label"
            :required="required" />
        <div
            v-click-outside="close"
            class="hds-input-container"
            :class="{'hds-selectbox__active': isOpen}"
        >
            <button
                v-if="icon"
                @click="isOpen = !isOpen"
                class="hds-icon-button">
              <hds-svg-icon icon="ColumnSelector" />
            </button>

            <button
                v-else
                @click="isOpen = !isOpen"
                class="hds-selectbox"
                tooltip-position="top"
            >
                <template v-if="selected.length === 0">
                    <span class="wr-placeholder">
                      {{placeholder}}
                        {{ $t(placeholder) }}
                    </span>
                </template>
                <template v-else>
                    {{ selectedItems }}
                </template>
            </button>
            <div class="hds-menu-dropdown-container hds-selectbox-container">
                <nav class="hds-menu-dropdown-container-content">
                    <div class="hds-row">
                        <div class="hds-col">
                          <hds-searchbox
                              v-if="search"
                              v-model="searchText"
                              :placeholder="$t('Search')"
                          />
                            <a
                                href="javascript:void(0);"
                                @click="toggleAll"
                                class="hds-selectbox-action">
                                <template v-if="selected.length ===  dataKeys.length">
                                    {{ $t('Clear all') }}
                                </template>
                                <template v-else>
                                    {{ $t('Select all') }}
                                </template>
                            </a>
                       </div>
                    </div>
                </nav>
                <nav class="hds-menu-dropdown-container-content hds-scrollbar hds-scrollbar-container scrollable">
                  <div class="hds-row">
                    <div class="hds-col">
                      <div
                          v-for="(item, key) in filteredData"
                          :key="key"
                          class="hds-menu-dropdown-container-item">
                          <span class="hds-checkbox-item">
                              <input
                                  :value="key"
                                  v-model="selected"
                                  :id="uuid + 'checkbox-' + key"
                                  type="checkbox"
                                  class="hds-checkbox"
                                  @change="$emit('input', selected)">
                              <label :for="uuid + 'checkbox-' + key"> {{ item }}</label>
                          </span>
                      </div>
                    </div>
                  </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            required: true
        },
        data: {
            type: Object,
            required: true
        },
        label: String,
        search: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String
        },
        allText: {
            type: String
        },
        required: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String
        }
    },
    methods: {
        close() {
            this.isOpen = false
        },
        toggleAll() {
            if ( this.dataKeys.length == this.selected.length ) {
                this.selected = []

            } else {
                this.selected = this.dataKeys
            }
            this.$emit('input', this.selected)
        }
    },
    computed: {
        filteredData() {
            if ( this.search != '' ) {
                let newData = {}
                const search = this.searchText.toLowerCase()
                for ( let key in this.data ) {
                    const el = this.data[key].toLowerCase()
                    if ( el.indexOf(search) > -1 ) {
                        newData[key] = this.data[key]
                    }
                }

                return newData
            }
            return this.data;
        },
        selectedItems() {
            if ( this.allText && this.dataKeys.length == this.selected.length ) {
                return this.$t(this.allText)
            }

            let text = []
            this.selected.forEach(el => {
                text.push(this.data[el])
            });
            return text.join(', ')
        }
    },
    data() {
        return {
            uuid: this.$uuid.v1(),
            selected: [],
            searchText: '',
            isOpen: false,
            dataKeys: Object.keys(this.data)
        }
    },
    created() {
        this.selected = this.value
    }
}
</script>

<style lang="css" scoped>
    .hds-input-container,
    .hds-selectbox {
        width: 100%;
    }
    .hds-selectbox {
      max-width: 195px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .hds-selectbox~.hds-selectbox-container {
        max-width: none;
    }
    .wr-placeholder {
        color: #8a8a8a
    }
    .hds-menu-dropdown-container {
        left: auto;
    }
    .hds-menu-dropdown-container-item {
        padding: 5px 20px;
    }
    .hds-selectbox-container {
      position: absolute;
      display: none;
    }
    .hds-selectbox__active .hds-selectbox-container {
      display: block;
    }
    >>> .hds-searchbox .hds-icons {
      margin: 0;
    }
</style>
