<template lang="html">
    <hds-dropdown icon="ellipsis">
        <template v-if="type == 'report'">
            <hds-dropdown-item @click="printReport(id)">{{ $t('Print') }}</hds-dropdown-item>
        </template>
        <template v-else>
            <hds-dropdown-item @click="sendReportsNow(id)">{{ $t('Send reports now') }}</hds-dropdown-item>
            <hds-dropdown-item @click="openWizard(id)">{{ $t('schedule_wizard.title.edit') }}</hds-dropdown-item>
            <hds-dropdown-item @click="deleteSchedule(id)">{{ $t('Delete schedule') }}</hds-dropdown-item>
        </template>

    </hds-dropdown>
</template>

<script>
    import {mapActions} from 'vuex'
    import {wizardMixin} from '../../../mixins/wizard'

    export default {
    mixins: [ wizardMixin ],
    props: {
        type: {
            type: String,
            default: 'report',
            validator: function (value) {
                return ['report', 'schedule'].indexOf(value) !== -1
            },
        },
        id: {
            type: [Number, String],
            required: true
        }
    },
    methods: {
        ...mapActions([
            'printReport',
            'sendReportsNow',
            'deleteSchedule'
        ])
    }
}
</script>

<style lang="css" scoped>
</style>
