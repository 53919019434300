<template lang="html">
    <div>
        <v-label
            v-if="label"
            :text="label"
            :required="required" />

        <textarea
            v-if="type == 'textarea'"
            class="hds-textarea hds-textarea-width-full"
            :value="value"
            :placeholder="$t(placeholder)"
            :disabled="disabled"
            @keyup.enter="onKeyUp"
            @input="filterValue"></textarea>

        <input
            v-else
            class="hds-input hds-input-width-full"
            :type="type"
            :value="value"
            :min="min"
            :max="max"
            :placeholder="$t(placeholder)"
            :disabled="disabled"
            @keyup.enter="onKeyUp"
            @input="filterValue">
    </div>
</template>

<script>
export default {
    props: {
        required: {
            type: Boolean,
            default: false
        },
        label: String,
        value: [String, Number],
        placeholder: String,
        disabled: Boolean,
        classes: String,
        type: {
            validator: function (value) {
                return ['text', 'number', 'textarea', 'password'].indexOf(value) !== -1
            },
            default: 'text'
        },
        min: Number,
        max: Number
    },
    methods: {
        filterValue(ev) {
            let value = ev.target.value.replace(/<[^>]+>/g, '')
            this.$emit('input', value)
        },
        onKeyUp(ev) {
            this.$emit('keyup', ev)
        }
    }
}
</script>

<style lang="css" scoped>
    textarea {
        resize: none;
    }
    .hds-input-width-full {
        width: 100%;
    }
    .has-error .hds-input {
        border-color: #bf0001;
    }
</style>
