<template lang="html">
    <a v-if="!!url && fallbackToPlainText" :href="url" class="hds-link" :target="target">
        {{ label }}
    </a>
    <span v-else>
        {{ label }}
    </span>
</template>

<script>
    export default {
        props: {
            url: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            target: {
                type: String,
                default: '_self'
            },
            fallbackToPlainText: {
                type: Boolean,
                default: false
            },
            print: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="css" scoped>
</style>
