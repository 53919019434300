<template lang="html">
    <div class="wr-print" v-if="validRequest">
        <hds-spinner
            v-if="loading"
            class="wr-print-spinner"
            :includeLine="false"
            :spinnerSize="35" />
        <component
            v-for="(reportId, key) in reportIds"
            :is="getComponent(reportId)"
            :key="key"
            :report="reportId"
            :domainId="domainId"
            :host_id="hostId"
            :scheduleType="scheduleType"
            :tableConfig="tableConfig"
            :period="period"
            :print="true"
            class="wr-report"
            @loaded="reportLoaded(reportId)"/>
    </div>
</template>

<script>
    import Report from './Report.vue'
    import Host from './Host.vue'
    import {isArray, uniq} from 'lodash'
    import {mapActions, mapGetters} from 'vuex'

    export default {
        components: {
            'view-report': Report,
            'view-host': Host,
        },
        methods: {
            ...mapActions([
                'selectDomain'
            ]),

            getComponent(reportId) {
                if ( reportId === 'host_details' ) {
                    return 'view-host'
                }
                return 'view-report'
            },
            reportLoaded(reportId) {
                this.loadedReports = uniq([
                    ...this.loadedReports,
                    reportId
                ])
                if ( this.reportIds.length === this.loadedReports.length ) {
                    this.loading = false
                    setTimeout(() => {
                        this.$root.$el.classList.remove('page-not-rendered')
                        if (this.printDialog) {
                            setTimeout(() => {
                                window.print()
                            }, 200)
                        }
                    }, 200)
                }
            }
        },
        computed: {
            ...mapGetters(['flatDomains'])
        },
        data() {
            return {
                reportIds: [],
                printDialog: false,
                tableConfig: null,
                scheduleType: null,
                validRequest: false,
                period: null,
                hostId: null,
                loading: false,
                loadedReports: []
            }
        },
        created() {
            this.loading = true
            let query = this.$router.currentRoute.query

            this.validRequest = !!query.reportIds && !isNaN(query.domainId)
            if (!this.validRequest) {
                console.error("'reportIds' or 'domainId' parameters are missing or invalid")
                return
            }

            this.reportIds = isArray(query.reportIds) ? query.reportIds : [(query.reportIds)]
            this.domainId = Number(query.domainId)
            this.$i18n.locale = query.locale
            this.printDialog = query.printDialog || false
            this.scheduleType = query.scheduleType || undefined
            this.tableConfig = { rowsLimit: query.rowsLimit || -1 }
            this.period = query.period || null
            this.hostId = query.host_id || null
        },
        mounted() {
            if (!this.validRequest) {
                return
            }
            setTargetDomain(this, this.domainId)
        }
    }

    function setTargetDomain(vue, domainId) {
        let domain = vue.flatDomains.find((value) => value.id === domainId);
        if (domain) {
            vue.selectDomain(domain)
        } else {
            console.error(`Failed to find domain with id = '${domainId}'`)
        }
    }
</script>

<style lang="css" scoped>
    @page {
        size: landscape;
    }
    .wr-report + .wr-report {
        page-break-before: always;
    }
</style>
<style lang="css">
  .wr-print-layout * {
    overflow: visible !important;
  }
</style>
