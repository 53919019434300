<template lang="html">
    <header class="hds-header-new wr-header">
        <div class="hds-header-wrapper-menus">
            <div class="hds-container">
                <div class="hds-row">
                    <div class="hds-col">
                        <router-link to="/" class="hds-header-product-logo">
                            <hds-svg-icon icon="logo" size="large" color="bright" />
                            <span class="hds-header-product-logo-text">
                                Policy Manager Web Reporting
                            </span>
                        </router-link>

                        <domain-selector v-if="showDomainSelector" />
                    </div>

                    <div class="hds-col hds-text-right">
                        <div class="wr-col wr-user">
                          <hds-svg-icon icon="account" color="bright" />
                            {{ userName }}
                        </div>
                        <div class="wr-col">
                            <button @click="logout" class="hds-button-link hds-button">
                              <hds-svg-icon icon="logout" color="bright" />
                              {{ $t('Log out') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    methods: {
        ...mapActions([
            'logout'
        ])
    },
    watch: {
        $route: {
            handler() {
                this.showDomainSelector = this.$route.name !== 'schedules' && this.$route.name !== 'host';
            },
            immediate: true
        }
    },
    computed: {
        ...mapGetters([
            'userName'
        ])
    },
    data() {
        return {
            showDomainSelector: true
        }
    }
}
</script>

<style lang="css" scoped>
    .hds-header-product-logo-text {
      font-weight: 500;
    }
    .hds-header-new {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        color: #fff
    }
    .wr-header >>> .hds-container {
        max-width: none;
    }
    .wr-col {
        display: inline-block;
        vertical-align: middle;
        padding-top: 9px;
        font-size: 14px;
    }
    .wr-user {
        margin-right: 10px;
    }
    .hds-text-right svg {
        margin-right: 4px;
        width: 16px;
    }
    .wr-col >>> .hds-button-link {
        text-decoration: none;
        color: #fff;
        font-size: 12px;
    }
    .wr-user svg {
      margin-top: -4px;
    }
    .hds-button-link svg {
      margin-top: -2px;
      fill: #fff;
    }
    .hds-header-product-logo {
      width: 335px;
    }
    .hds-header-product-logo svg {
      position: relative;
      margin-right: 7px;
    }
</style>
