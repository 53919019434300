<template lang="html">
    <div class="treemap">
        <svg :height="height" style="margin-left: 0;" :width="width">
            <g style="shape-rendering: crispEdges;" transform="translate(0,20)">
                <transition-group name="list" tag="g" class="depth">
                    <g
                        v-if="selectedNode"
                        class="children"
                        v-for="(children) in selectedNode._children"
                        :key="'c_' + children.key"
                        >

                        <!-- Generate the children squares (only visible on hover of a square) -->
                        <rect
                            v-for="child in children._children"
                            class="child"
                            :id="child.key"
                            :key="child.key"
                            :height="y(child.y1) - y(child.y0)"
                            :width="x(child.x1) - x(child.x0)"
                            :x="x(child.x0)"
                            :y="y(child.y0)">
                        </rect>

                        <!-- The visible square rect element -->
                        <rect
                            :class="children._children ? 'parent' : 'child'"
                            class="visible-rect"
                            v-on:click="selectNode"
                            :id="children.key"
                            :key="children.key"
                            :x="x(children.x0)"
                            :y="y(children.y0)"
                            :width="x(children.x1 - children.x0 + children.parent.x0)"
                            :height="y(children.y1 - children.y0 + children.parent.y0)">

                            <title>
                                {{ children.data.name }} | {{ children.value }}
                            </title>
                        </rect>

                        <text
                            dy="1em"
                            :key="'t_' + children.key"
                            :x="x(children.x0) + 10"
                            :y="y(children.y0) + 15"
                            style="fill-opacity: 1;">
                            {{ children.data.name }}
                        </text>

                        <text
                            dy="2.25em"
                            :key="'t_' + children.key + '_v'"
                            :x="x(children.x0) + 10"
                            :y="y(children.y0) + 15"
                            style="fill-opacity: 1;">

                            {{ children.value }}
                        </text>

                    </g>
                </transition-group>

                <g class="grandparent">
                    <rect
                        :height="margin.top"
                        :width="width"
                        :y="(margin.top * -1)"
                        v-on:click="selectNode"
                        :id="parentId">
                    </rect>

                    <text dy=".65em" x="2" y="-18">
                        {{ selectedNodePath}}
                    </text>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
import {scaleLinear} from 'd3-scale'
import {hierarchy, treemap} from 'd3-hierarchy'

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data () {
        return {
            jsonData: null,
            rootNode: {},
            margin: {
                top: 20,
                right: 0,
                bottom: 0,
                left: 0
            },
            width: 900,
            height: 500,
            selected: null
        }
    },
    mounted () {
        this.drawTree()
    },
    watch: {
        data: {
            deep: true,
            handler() {
                this.$nextTick(() => {
                    this.drawTree()
                });
            }
        }
    },
    computed: {
        parentId () {
            if (this.selectedNode.parent === undefined || this.selectedNode.parent === null) {
                return this.selectedNode.key
            } else {
                return this.selectedNode.parent.key
            }
        },

        x () {
            return scaleLinear()
                .domain([0, this.width])
                .range([0, this.width])
        },

        y () {
            return scaleLinear()
                .domain([0, this.height - this.margin.top - this.margin.bottom])
                .range([0, this.height - this.margin.top - this.margin.bottom])
        },

        treemap () {
            return treemap()
                .size([this.width, this.height])
                .round(false)
                .paddingInner(0)
        },

        selectedNode () {
            let node = null
            if (this.selected) {
                let nd = this.getNodeById(this.rootNode, this.selected, this)
                if (nd._children) {
                    node = nd
                } else {
                    node = nd.parent
                }
            } else {
                node = this.rootNode
            }

            this.x.domain([node.x0, node.x0 + (node.x1 - node.x0)])
            this.y.domain([node.y0, node.y0 + (node.y1 - node.y0)])
            return node
        },

        selectedNodePath() {
            if ( typeof this.selectedNode.key == 'undefined') {
                return ''
            }
            return this.selectedNode.key.split('.').map( s => this.$t("alert." + s) ).join(' / ')
        }
    },
    methods: {
        drawTree() {
            this.selected = null
            this.jsonData = this.data
            this.initialize()
            this.accumulate(this.rootNode, this)
            this.treemap(this.rootNode)
        },
        initialize () {
            if (this.jsonData) {
                this.rootNode = hierarchy(this.jsonData)
                    .eachBefore(function (d) { d.key = (d.parent ? d.parent.key + '.' : '') + d.data.key })
                    .sum((d) => d.value)
                    .sort(function (a, b) {
                        return b.height - a.height || b.value - a.value
                    })
                this.rootNode.x = this.rootNode.y = 0
                this.rootNode.x1 = this.width
                this.rootNode.y1 = this.height
                this.rootNode.depth = 0
            }
        },

        accumulate (d, context) {
            d._children = d.children
            if (d._children) {
                d.value = d._children.reduce(function (p, v) { return p + context.accumulate(v, context) }, 0)
                return d.value
            } else {
                return d.value
            }
        },

        getNodeById (node, id, context) {
            if (node.key === id) {
                return node
            } else if (node._children) {
                for (let i = 0; i < node._children.length; i++) {
                    let nd = context.getNodeById(node._children[i], id, context)
                    if (nd) {
                        return nd
                    }
                }
            }
        },

        selectNode (event) {
            this.selected = event.target.id
        }
    }
}
</script>

<style lang="css" scoped>
    #treemap {
        padding-top: 5px;
    }
    text {
        pointer-events: none;
        font-size: 14px;
        fill: #505050;
    }
    .grandparent text {
        font-weight: normal;
        font-size: 14px;
    }
    rect {
        fill: none;
        stroke: #89c4ff;
        stroke-width: 2px;
    }
    rect.parent {
        stroke-width: 4px;
        fill: #fff;
    }
    .visible-rect {
        stroke: #fff;
        stroke-width: 4px;
    }
    .grandparent rect {
        stroke: none;
        fill: #fff;
    }
    .grandparent text {
        fill: #006fdd;
    }
    .grandparent:hover text {
        fill: #05a;
    }
    .children rect.parent,
    .grandparent rect {
        cursor: pointer;
    }
    .children rect.parent {
        stroke: #fff;
        fill: rgba(141, 197, 255, 0.5);
    }
    .children rect.parent:hover {
        outline-color: #0078ee;
        outline-style: solid;
        outline-width: 3px;
        outline-offset: -6px;
    }
    .children rect.child {
        fill: #cae4ff;
        opacity: .9;
        cursor: default;
    }
    .list-enter-active, .list-leave-active {
        transition: all 1s;
    }
    .list-enter, .list-leave-to /* .list-leave-active for <2.1.8 */ {
        opacity: 0;
    }
</style>
