<template lang="html">
    <div>
        <span
            v-for="(tag, key) in tags"
            :key="key"
            class="hds-tags hds-tags-low hds-margin-small-right">
            {{ $t('report_tag.' + tag) }}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        tags: {
            type: Array,
            default: function() {
                return []
            }
        }
    }
}
</script>

